import { DiscountMappingEntity, EMPTY_DISCOUNT_MAPPING_ENTITY } from "domain/entity/DiscountMapping/DiscountMappingEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { DiscountMappingRepository } from "domain/repository/DiscountMapping/DiscountMappingRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import _ from "lodash";
import { DiscountMappingModel } from "presentation/model/DiscountMapping/DiscountMappingModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface DiscountMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DiscountMappingModel>> | ((value: SetStateAction<DiscountMappingModel>) => void),
    ],
    discountMappingRepo:DiscountMappingRepository,
    chargeTypeRepo: ChargeTypeRepository,
    companyRepo: CompanyRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
}

export const DiscountMappingVM = ({dispatch, discountMappingRepo,chargeTypeRepo,standardTariffCodeRepo,
    companyRepo,
}:DiscountMappingVMProps) => {
    const [discountMappingDispatch] = dispatch;

    const loadDropdownOption = async() => {

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOption:DropdownProps[] = [];
                let subChargeTypeDropdownOption:{[key:string]: DropdownProps[]} = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                discountMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};
                let allTariffCodeeDropdownOptions:DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t => 
                        t.value === tariff.tariffCode);

                    if(!isTariffCodeExisted){
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })            

                discountMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        chargeTariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        chargeTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                discountMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

    }

    const searchAllDiscountMappingList = async() => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                currentEditRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows:[],
                discountMappingList: [],
            }
        })
       await discountMappingRepo.searchAllDiscountMappingList().then(data =>{
            if(data){
                discountMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        discountMappingList: data,
                        currentRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                        currentEditRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                        isShowEditPanel: false,
                        isAdd: false,
                        isEdit: false,
                        updatedRows:[],
                    }
                })
            }else{
                discountMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        discountMappingList: [],
                        currentRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                        currentEditRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                        isShowEditPanel: false,
                        isAdd: false,
                        isEdit: false,
                        updatedRows:[],
                    }
                })
            }
        })
    }

    const onRowDrag = (rows: DiscountMappingEntity[]) => {
        discountMappingDispatch(prevState =>{ 
            const prevRows = prevState.discountMappingList;
            const toChangedRows = rows.map((e, index) => ({...e,tempPriority: prevRows[index].priority})).filter(e=>e.tempPriority!==e.priority);      
            return ({
                ...prevState,
                discountMappingList: rows,
                updatedRows: toChangedRows,
        })})
    }

    const onApply = async (updatedRows: DiscountMappingEntity[]) => {

        return await discountMappingRepo.onApply(updatedRows);
    }

    const onUnconfirm = async (selectedRows: DiscountMappingEntity[]) => {

        return await discountMappingRepo.onUnconfirm(selectedRows);
    }
    const onSubmit = async (selectedRows: DiscountMappingEntity[]) => {

        return await discountMappingRepo.onSubmit(selectedRows);
    }
    const onApprove = async (selectedRows: DiscountMappingEntity[]) => {

        return await discountMappingRepo.onApprove(selectedRows);
    }

    const updateSelectedRows = async (rows:DiscountMappingEntity[]) => {

        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                // discountMappingList: [...allRows],
                selectedRows: rows,
                currentRow: rows.length===1?rows[0]:{...EMPTY_DISCOUNT_MAPPING_ENTITY},
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        if(fieldKey === 'chargeType'){
            discountMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val===prevState.currentEditRow.chargeType?prevState.currentEditRow.subChargeType:''
                    },
                }
            })
        }
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: DiscountMappingEntity) => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: DiscountMappingEntity) => {
        return discountMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_DISCOUNT_MAPPING_ENTITY},
                isAdd: true,
                isShowEditPanel: true,
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked?"Y":"N",
                },
            }
        })
    } 

    const onResetClick = async (isAdd:boolean) => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd?{...EMPTY_DISCOUNT_MAPPING_ENTITY}:prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }
    const onRejectClick = async () => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                isShowRejectPad: true,
            }
        })
    }
    const cancelRejectReasonModal = async () => {
        discountMappingDispatch(prevState => {
            return {
                ...prevState,
                isShowRejectPad: false,
                isRejecting: false,
                currentRow: {
                    ...prevState.currentRow,
                    reasonCode:prevState.currentRow.reasonCode,
                }
            }
        })
    }

    const onRejectReasonTextAreaChange = (fieldValue: IFieldValue) => {
        let val: any = fieldValue;
        return discountMappingDispatch(prevState => {
          return {
              ...prevState,
              currentRow:{
                    ...prevState.currentRow,
                    reasonCode: val
              }
          }
        });
      }

      const closeRejectReasonModal = () => {
        discountMappingDispatch(prevState => ({
            ...prevState,
            isShowRejectPad: false,
            isRejecting: true,
        }))
    };

      const onReject = async (selectedRow:DiscountMappingEntity) => {
        closeRejectReasonModal();
        await discountMappingRepo.onReject([selectedRow]).then(async (data) => {
            await searchAllDiscountMappingList();
            discountMappingDispatch(prevState => ({
                ...prevState,
                isRejecting: false,
            }))
        });
    };

    return {
        onReject: onReject,
        onRejectReasonTextAreaChange: onRejectReasonTextAreaChange,
        cancelRejectReasonModal: cancelRejectReasonModal,
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onCheckboxChange: onCheckboxChange,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        onApply: onApply,
        onUnconfirm: onUnconfirm,
        onSubmit: onSubmit,
        onApprove: onApprove,
        onRejectClick: onRejectClick,
        onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllDiscountMappingList: searchAllDiscountMappingList,
    }
}