import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { DiscountMappingRepoImpl } from "domain/repository/DiscountMapping/DiscountMappingRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { useDiscountMappingTracked } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { DiscountMappingVM } from "presentation/viewModel/DiscountMapping/DiscountMappingVM";
import { useMemo } from "react";

export const useDiscountMappingVM = () => {
    const [, setDiscountMappingState] = useDiscountMappingTracked();
    const discountMappingVM = useMemo(() =>
        DiscountMappingVM({
            dispatch: [setDiscountMappingState],
            discountMappingRepo: DiscountMappingRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
        }), [setDiscountMappingState])

    return discountMappingVM
}