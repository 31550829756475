import _ from "lodash";
import { DiscountMappingConstant } from "presentation/constant/DiscountMapping/DiscountMappingConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useDiscountMappingVM } from "presentation/hook/DiscountMapping/useDiscountMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useDiscountMappingTracked } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const DiscountMappingTitleBar:React.FC = () => {
    const DISCOUNT_MAPPING_CONSTANT= DiscountMappingConstant.Title;
    const discountMappingVM = useDiscountMappingVM();
    const [discountMappingState] = useDiscountMappingTracked();
    const [isLoading, setIsLoading] = useState(false);
    const {updatedRows,selectedRows} = discountMappingState;
    const messageBarVM = useMessageBarVM();  
    
    const handleAdd = useCallback(() => {
        discountMappingVM.onAddClick();
    }, [discountMappingVM]);

    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setIsLoading(true);
    //     const res = await discountMappingVM.onApply(updatedRows);
    //     setIsLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining(res.data);
    //     }else{
    //         messageBarVM.showSuccess('Apply Successful.');
    //         await discountMappingVM.searchAllDiscountMappingList();
    //     }
    //     setIsLoading(false);
    // }, [discountMappingVM, messageBarVM, updatedRows]);

    const handleUnconfirm = useCallback(async () => {
        if(_.isEmpty(selectedRows)) return;
        setIsLoading(true);
        const res = await discountMappingVM.onUnconfirm(selectedRows);
        setIsLoading(false);
        if(!!!res || !res.success){
            messageBarVM.showWarining(res.data);
        }else{
            messageBarVM.showSuccess('Unconfirm Successful.');
            await discountMappingVM.searchAllDiscountMappingList();
        }
        setIsLoading(false);
    }, [discountMappingVM, messageBarVM, selectedRows]);

    const handleSubmit = useCallback(async () => {
        if(_.isEmpty(selectedRows)) return;
        setIsLoading(true);
        const res = await discountMappingVM.onSubmit(selectedRows);
        setIsLoading(false);
        if(!!!res || !res.success){
            messageBarVM.showWarining(res.data);
        }else{
            messageBarVM.showSuccess('Submit Successful.');
            await discountMappingVM.searchAllDiscountMappingList();
        }
        setIsLoading(false);
    }, [discountMappingVM, messageBarVM, selectedRows]);

    const handleApprove = useCallback(async () => {
        if(_.isEmpty(selectedRows)) return;
        setIsLoading(true);
        const res = await discountMappingVM.onApprove(selectedRows);
        setIsLoading(false);
        if(!!!res || !res.success){
            messageBarVM.showWarining(res.data);
        }else{
            messageBarVM.showSuccess('Approve Successful.');
            await discountMappingVM.searchAllDiscountMappingList();
        }
        setIsLoading(false);
    }, [discountMappingVM, messageBarVM, selectedRows]);

    const handleReject = useCallback(async () => {
        discountMappingVM.onRejectClick();

    }, [discountMappingVM]);
    
    // const handleRefresh = useCallback(async () => {
    //     setIsLoading(true);
    //     discountMappingVM.searchAllDiscountMappingList().then((data) => {            
    //         setIsLoading(false)
    //     }).catch(error => {            
    //         setIsLoading(false)
    //     });
    // },[discountMappingVM]);

    // const isApplyDisable = () => {
    //     if(updatedRows.length !== 0) return false
    //     return true;
    // }

    const isSubmitDisable = () => {
        if(updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status !== 'FINALIZED')) return false
        return true;
    }
    const isUnconfirmDisable = () => {
        if(updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status === 'FINALIZED' && entity.confirmedDate)) return false
        return true;
    }
    const isRejectDisable = () => {
        if(updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status === 'FINALIZED' && !entity.confirmedDate)) return false
        return true;
    }
    const isApproveDisable = () => {
        if(updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status === 'FINALIZED' && !entity.confirmedDate)) return false
        return true;
    }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{DISCOUNT_MAPPING_CONSTANT.TITLE}</HeaderTitle>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/>
            <div className="add-seperator"/> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
            {/* <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Secondary"}  disabled={isApplyDisable()} onClick={handleApply} /> */}
            <div className="add-seperator"/>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_UNCONFIRM} size={"Small"} theme={"Secondary"}  disabled={isUnconfirmDisable()} onClick={handleUnconfirm} />
            <HPHButton label={"Submit"} size={"Small"} theme={"Secondary"}  disabled={isSubmitDisable()} onClick={handleSubmit} />
            <div className="add-seperator"/>
            <HPHButton label={"Reject"} size={"Small"} theme={"Secondary"}  disabled={isRejectDisable()} onClick={handleReject} />
            <HPHButton label={"Approve"} size={"Small"} theme={"Primary"}  disabled={isApproveDisable()} onClick={handleApprove} />
        </StyledAction>
    </Sidebarheader>
}

export default memo(DiscountMappingTitleBar);