
export const DiscountMappingConstant = {
    Table:{
        PRIORITY: "Priority",
        BILL_TO_COMPANY: "Bill To Company",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        CHARGE_TAR_TYPE: "Charge Tar Type",
        CHARGE_TAR_CODE: "Charge Tar Code",
        EFFECTIVE_DATE: "Effective Date",
        EXPIRY_DATE: "Expiry Date",
        CONFIRMED_DATE: "Confirmed Date",
        STATUS: "Status",
        REASON: "Reason",
        DISCOUNT: "Discount",
        DISCOUNT_TYPE: "Discount Type",
        DISCOUNT_WITH_TAX: "Discount With Tax",
        DISCOUNT_TAR_TYPE: "Discount Tar Type",
        DISCOUNT_TAR_CODE: "Discount Tar Code",
        ACTIVE_IND: "Active Ind.",
    },
    Title:{
        TITLE: "Discount Setting",
        MAPPING_FROM: "From",
        MAPPING_TO: "To",
    },
}
export const discountMappingRequiredFieldList: string[] = [
    'billToCompanyCode',
    'discount',
    'effectiveDate',
    'expiryDate',
    'discountType',
    'discountWithTax',
    'tariffType',
    'tariffCode',
    'activeInd',
];

export const discountMappingCSVColumnMapping: string[] = [
    'chargeType',
    'subChargeType',
    'chargeOnCompany',
    'billToCompany',
    'opsLine',
    'marshallingType',
    'cntrSize',
    'cntrType',
    'cntrStatus',
    'shipmentType',
    'serviceCode',
    'modalityOfArrival',
    'modalityOfDeparture',
    'marshallingFrom',
    'marshallingTo',
    'endVisitInd',
    'chargeInd',
    'bundleInd',
    'cntrTspStatus',
    'operationType',
    'fromTml',
    'toTml',
    'billingType',
    'activeInd',
];