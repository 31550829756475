import { SelectionChangedEvent } from "ag-grid-community";
import { DiscountMappingEntity } from "domain/entity/DiscountMapping/DiscountMappingEntity";
import _ from "lodash";
import { INITIAL_DISCOUNT_MAPPING_COL_DEF } from "presentation/constant/DiscountMapping/DiscountMappingColumnDefinition";
import { useDiscountMappingVM } from "presentation/hook/DiscountMapping/useDiscountMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDiscountMappingTracked } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const DiscountMappingTablePanel = () => {
    const gridRef: any = useRef(null);
    const [discountMappingState] = useDiscountMappingTracked();
    const discountMappingVM = useDiscountMappingVM();
    const messageBarVM = useMessageBarVM(); 
    
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<DiscountMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ initialAllRows, setInitialAllRows ] = useState<boolean>(true);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        setOnTableSelectionClicked(true);
        discountMappingVM.updateSelectedRows(selectedRows);
      }, [discountMappingVM])

    // const handleRowDrag = useCallback((e: any, updatedRows: DiscountMappingEntity[], movedIndex: number, overIndex: number)=>{
    //     discountMappingVM.onRowDrag(updatedRows);
    // },[discountMappingVM])


    const handleRowDoubleClick = useCallback((entity: DiscountMappingEntity) => {
        // if(updatedRows.length > 0){
        //     messageBarVM.showError("Modified data has not been saved.Please save or refersh the data first."); 
        //     return;
        // }
        discountMappingVM.onRowDoubleClick(entity);
  }, [discountMappingVM])

  
  const getAllRows = useCallback(() => {
    return allRows;
}, [allRows]);

const getSelectedRows = useCallback(() => {
    return discountMappingState.selectedRows;
}, [discountMappingState.selectedRows]);

const getShowMoveCursor = useCallback(() => {
    return showMoveCursor;
}, [showMoveCursor]);
//for right click menu with move after end

useEffect(() => {
    const columnDefs = (INITIAL_DISCOUNT_MAPPING_COL_DEF.slice());

    if (!discountMappingState.selectedRows || discountMappingState.selectedRows.length <= 0) {
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    }
    changeCursor("discount-mapping-table", getShowMoveCursor());
});

useEffect(() => {
    if (!onTableSelectionClicked) return;
    gridRef?.current?.gridRef.current.api?.deselectAll();
    changeCursor("discount-mapping-table", getShowMoveCursor());
}, [getShowMoveCursor, onTableSelectionClicked]);

useEffect(() => {
    const handleRightClickMenu = (event: any) => {
        if (_.isEmpty(discountMappingState.selectedRows) || !allowUpdate) {
            return;
        }
        event.preventDefault();
        rightClickRef.current.show(event);
    };
    document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

    return () => {
        document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
    };
}, [messageBarVM, rightClickRef, allowUpdate, discountMappingState.selectedRows]);

// for right click menu with move after begin
const onRefreshRow = useCallback((newAllRows: any[]) => {
    setAllRows(newAllRows);
    discountMappingVM.updateSelectedRows([]);
    setShowMoveCursor(true);
}, [discountMappingVM]);

const onMoveCancel = useCallback(() => {
    setInitialAllRows(true);
    setAllRows(discountMappingState.discountMappingList);
    discountMappingVM.updateSelectedRows([]);
    setShowMoveCursor(false);
}, [discountMappingState.discountMappingList, discountMappingVM]);

const onSaveMove = useCallback(async (newAllRows: any[]) => {
    if (_.isEmpty(newAllRows)) return;

    setIsLoading(true);
    
    discountMappingVM.onApply(newAllRows).then((res) => {
        if (!res || !res.success) {
            messageBarVM.showWarining('Move failed.');
            setInitialAllRows(true);
            setIsLoading(false);

            discountMappingVM.updateSelectedRows([]);
            setShowMoveCursor(false);
        } else {
            discountMappingVM.searchAllDiscountMappingList().then((data) => {
                setInitialAllRows(true);
                setIsLoading(false);

                discountMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            }).catch((e) => {
                setInitialAllRows(true);
                setIsLoading(false);

                discountMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            })
        }
    })
}, [messageBarVM, discountMappingVM]);

    const memoDiscountMappingTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
        <HPHTable
            id='discount-mapping-table'
            isNewColumnSetting={true}
            columns={INITIAL_DISCOUNT_MAPPING_COL_DEF}
            data={allRows ?? []}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: DiscountMappingEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            // isRowDrag={true}
            // onRowDragged={handleRowDrag}
            rowSelection={showMoveCursor ? "single" : "multiple"} 
            onBodyScroll={(e: any) => changeCursor("discount-mapping-table", getShowMoveCursor())}
        />
        </div>
        </>
        );
    },[allRows, allowUpdate, getAllRows, getSelectedRows, getShowMoveCursor, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && discountMappingState.discountMappingList && !_.isEmpty(discountMappingState.discountMappingList)) {
            setAllRows(discountMappingState.discountMappingList.map((taxMappingEntity, index) => ({
                ...taxMappingEntity,
                index: index || 0
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, discountMappingState.discountMappingList]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoDiscountMappingTable}
        </TableWrapper>
        </>;
}

export default memo(DiscountMappingTablePanel);
