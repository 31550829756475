import { DiscountMappingConstant, discountMappingRequiredFieldList } from "presentation/constant/DiscountMapping/DiscountMappingConstant";
import { discountTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useDiscountMappingVM } from "presentation/hook/DiscountMapping/useDiscountMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useDiscountMappingTracked } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const DiscountMappingEditPanel = () => {
    const DISCOUNT_MAPPING_CONSTANT = DiscountMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [discountMappingState] = useDiscountMappingTracked();
    const discountMappingVM = useDiscountMappingVM();
    const { currentEditRow, isAdd, isEdit } = discountMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (!currentEditRow.billToCompanyCode) {
            messageBarVM.showError("Bill To Company is mandatory.");
            return;
        }
        if (!currentEditRow.effectiveDate) {
            messageBarVM.showError("Effective Date is mandatory.");
            return;
        }
        if (!currentEditRow.expiryDate) {
            messageBarVM.showError("Expiry Date is mandatory.");
            return;
        }
        if (!currentEditRow.discount) {
            messageBarVM.showError("Discount is mandatory.");
            return;
        }
        if (!currentEditRow.discountType) {
            messageBarVM.showError("Discount Type is mandatory.");
            return;
        }
        if (currentEditRow.discountType === 'PERCENT' && currentEditRow.discount >= 100) {
            messageBarVM.showError("The discount should be less than 100%.");
            return;
        }
        if (!currentEditRow.discountWithTax) {
            messageBarVM.showError("Discount With Tax is mandatory.");
            return;
        }
        if (!currentEditRow.tariffType) {
            messageBarVM.showError("Discount Tariff Type is mandatory.");
            return;
        }
        if (!currentEditRow.tariffCode) {
            messageBarVM.showError("Discount Tariff Code is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await discountMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save Successfully.");
            discountMappingVM.searchAllDiscountMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [discountMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        discountMappingVM.onCloseEidtPanel();
    }, [discountMappingVM]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        discountMappingVM.onHeaderFieldChange(fieldName, fieldValue);
    }, [discountMappingVM]);

    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEditRow.chargeType ? discountMappingState.dynamicOptions.subChargeTypeDropdownOptions[currentEditRow.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEditRow.chargeType, discountMappingState.dynamicOptions.subChargeTypeDropdownOptions])

    const memoTariffCodeOptions = useMemo(() => {
        return currentEditRow.tariffType
            ? discountMappingState.dynamicOptions.tariffCodeDropdownOptions[currentEditRow.tariffType]
            : [];
    }, [currentEditRow.tariffType, discountMappingState.dynamicOptions.tariffCodeDropdownOptions]);

    const memochargeTariffCodeOptions = useMemo(() => {
        return currentEditRow.chargeTariffType
            ? discountMappingState.dynamicOptions.chargeTariffCodeDropdownOptions[currentEditRow.chargeTariffType]
            : [];
    }, [currentEditRow.chargeTariffType, discountMappingState.dynamicOptions.chargeTariffCodeDropdownOptions]);

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.tariffType || ''}
                fieldValue={currentEditRow?.tariffType}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TAR_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                options={discountMappingState.dynamicOptions.tariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.tariffType, DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TAR_TYPE, discountMappingState.dynamicOptions.tariffTypeDropdownOptions, discountMappingVM])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.tariffCode || ''}
                fieldValue={currentEditRow?.tariffCode}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TAR_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                options={memoTariffCodeOptions}
                maxLength={60}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.tariffCode, DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TAR_CODE, memoTariffCodeOptions, discountMappingVM])

    const memoChargeTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.chargeTariffType || ''}
                fieldValue={currentEditRow?.chargeTariffType}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.CHARGE_TAR_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeTariffType'}
                options={discountMappingState.dynamicOptions.chargeTariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.chargeTariffType, DISCOUNT_MAPPING_CONSTANT.CHARGE_TAR_TYPE, discountMappingState.dynamicOptions.chargeTariffTypeDropdownOptions, discountMappingVM])

    const memoChargeTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeTariffCode || ''}
                fieldValue={currentEditRow?.chargeTariffCode}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.CHARGE_TAR_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeTariffCode'}
                options={memochargeTariffCodeOptions}
                maxLength={60}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.chargeTariffCode, DISCOUNT_MAPPING_CONSTANT.CHARGE_TAR_CODE, memochargeTariffCodeOptions, discountMappingVM])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeType || ''}
                fieldValue={currentEditRow?.chargeType}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                maxLength={60}
                options={discountMappingState.dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DISCOUNT_MAPPING_CONSTANT.CHARGE_TYPE, discountMappingState.dynamicOptions.chargeTypeDropdownOptions, discountMappingVM, currentEditRow?.chargeType, isAdd, isEdit])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.subChargeType || ''}
                fieldValue={currentEditRow?.subChargeType}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                maxLength={60}
                options={memoSubChgTypeOptions}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DISCOUNT_MAPPING_CONSTANT.SUB_CHARGE_TYPE, discountMappingVM, currentEditRow?.subChargeType, isAdd, isEdit, memoSubChgTypeOptions])


    const memoStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.status || ''}
                fieldValue={currentEditRow?.status}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.STATUS}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'status'}
                maxLength={60}
                sort={false}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEditRow?.status, DISCOUNT_MAPPING_CONSTANT.STATUS, discountMappingVM])

    const memoReasonCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.reasonCode || ''}
                fieldValue={currentEditRow?.reasonCode}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'reasonCode'}
                maxLength={60}
                sort={false}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEditRow?.reasonCode, DISCOUNT_MAPPING_CONSTANT.REASON, discountMappingVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.billToCompanyCode || ''}
                fieldValue={currentEditRow?.billToCompanyCode}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompanyCode'}
                maxLength={60}
                sort={false}
                options={discountMappingState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.billToCompanyCode, DISCOUNT_MAPPING_CONSTANT.BILL_TO_COMPANY, discountMappingState.dynamicOptions.companyCodeDropdownOptions, discountMappingVM])

    const memoDiscount = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={DISCOUNT_MAPPING_CONSTANT.DISCOUNT}
                maxLength={60}
                fieldName={"discount"}
                value={currentEditRow?.discount ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [DISCOUNT_MAPPING_CONSTANT.DISCOUNT, currentEditRow?.discount, isAdd, isEdit, onNumberFieldChange])

    const memoDiscountType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.discountType || ''}
                fieldValue={currentEditRow?.discountType}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'discountType'}
                maxLength={60}
                sort={false}
                options={discountTypeDropdownOption}
                requiredFieldList={discountMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    discountMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.discountType, DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TYPE, discountMappingVM])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={!(isAdd || isEdit)}
                label={DISCOUNT_MAPPING_CONSTANT.EFFECTIVE_DATE}
                width="260px"
                date={currentEditRow?.effectiveDate}
                fieldName="effectiveDate"
                errorMessage={""}
                onDateChange={discountMappingVM.onHeaderFieldChange} />
        </div>
        , [DISCOUNT_MAPPING_CONSTANT.EFFECTIVE_DATE, currentEditRow?.effectiveDate, discountMappingVM.onHeaderFieldChange, isAdd, isEdit])

    const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={!(isAdd || isEdit)}
                label={DISCOUNT_MAPPING_CONSTANT.EXPIRY_DATE}
                width="260px"
                date={currentEditRow?.expiryDate}
                fieldName="expiryDate"
                errorMessage={""}
                onDateChange={discountMappingVM.onHeaderFieldChange} />
        </div>
        , [DISCOUNT_MAPPING_CONSTANT.EXPIRY_DATE, currentEditRow?.expiryDate, discountMappingVM.onHeaderFieldChange, isAdd, isEdit])


    const memoDiscountWithTax = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                fieldKey={"discountWithTax"}
                requiredFieldList={[]}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.DISCOUNT_WITH_TAX}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.discountWithTax === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => discountMappingVM.onCheckboxChange(e.checked, "discountWithTax")}
            />
        </div>
        , [DISCOUNT_MAPPING_CONSTANT.DISCOUNT_WITH_TAX, currentEditRow?.discountWithTax, isAdd, isEdit, discountMappingVM])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={DISCOUNT_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => discountMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [DISCOUNT_MAPPING_CONSTANT.ACTIVE_IND, discountMappingVM, currentEditRow?.activeInd, isAdd, isEdit])

    const handleEdit = useCallback(() => {
        discountMappingVM.onEditClick();
    }, [discountMappingVM]);

    const handleReset = useCallback(() => {
        discountMappingVM.onResetClick(isAdd);
    }, [discountMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: DiscountMappingConstant.Title.TITLE },
                    { title: DiscountMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Discount Setting"}
                                    {!isAdd && (currentEditRow.priority + "/" + currentEditRow.status)}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <br />
                        <CriteriaItemContainer>
                            {memoStatus}
                            {memoBillToCompany}
                            {memoChargeType}
                            {memoSubChargeType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeTariffType}
                            {memoChargeTariffCode}
                            {memoEffectiveDate}
                            {memoExpiryDate}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoDiscountType}
                            {memoDiscount}
                            {memoTariffType}
                            {memoTariffCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                            {memoDiscountWithTax}
                            {memoReasonCode}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(DiscountMappingEditPanel);
